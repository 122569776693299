import styled from '@emotion/styled';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { colors, spacing } from '../../../../utils/styleguide';
import IconButton from '../../buttons/IconButton';
import Typography from '../../text/Typography';

const MainWrapper = styled.div`
  width: 100%;
`;

const HeadlineWrapper = styled.div<{ isExpanded: boolean; onClick?: () => void }>`
  cursor: ${({ isExpanded, onClick }) => (onClick && !isExpanded ? 'pointer' : 'default')};
  transition: color 300ms ease-in-out;
  :hover h4,
  :hover svg path {
    color: ${colors.blackPrimary};
    stroke: ${colors.blackPrimary};
  }
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: ${spacing[2]}px 0;
  border-bottom: ${({ isExpanded }) =>
    `1px solid ${isExpanded ? colors.blackPrimary : colors.blackSecondary}`};
`;

const Expander = styled.div<{ isExpanded: boolean }>`
  align-content: ${({ isExpanded }) => (isExpanded ? 'none' : 'center')};
  transition: transform 0.5s ease-in-out;
  transform: ${({ isExpanded }) => (isExpanded ? 'none' : 'rotate(135deg)')};
`;

const ExpandedContentWrapper = styled.div<{ contentHeight: number }>`
  display: flex;
  height: ${({ contentHeight }) => (contentHeight > 0 ? `${contentHeight}px` : 0)};
  padding-top: ${({ contentHeight }) => (contentHeight > 0 ? `${spacing[4]}px` : 0)};
  padding-bottom: ${({ contentHeight }) => (contentHeight > 0 ? `${spacing[4]}px` : 0)};
  flex: 1;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
`;

interface AccordionItemProps {
  title: string;
  expandedContent: ReactNode;
  className?: string;
  isFaq?: boolean;
  onClick?: () => void;
  isExpanded: boolean;
}

export default function AccordionItem({
  title,
  expandedContent,
  className,
  isFaq,
  onClick,
  isExpanded,
}: AccordionItemProps) {
  const [contentHeight, setContentHeight] = useState(0);
  const ref = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isExpanded) {
      setContentHeight(ref.current?.scrollHeight ?? 0);
    } else {
      setContentHeight(0);
    }
  }, [isExpanded]);

  return (
    <MainWrapper className={className} ref={wrapperRef}>
      <HeadlineWrapper isExpanded={isExpanded} role="button" onClick={onClick}>
        <Typography
          variant="h4"
          color={isExpanded ? colors.blackPrimary : colors.blackSecondary}
          itemProp={isFaq ? 'name' : undefined}
        >
          {title}
        </Typography>

        <Expander isExpanded={isExpanded}>
          <IconButton
            iconName={'x-ol'}
            variant="onlyIcon"
            ariaLabel={isExpanded ? 'close button' : 'expand button'}
            stroke={isExpanded ? colors.blackPrimary : colors.blackSecondary}
          />
        </Expander>
      </HeadlineWrapper>

      <ExpandedContentWrapper contentHeight={contentHeight} ref={ref}>
        {expandedContent}
      </ExpandedContentWrapper>
    </MainWrapper>
  );
}
